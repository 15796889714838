/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.yes {
    background-color: #eb445a !important;
    color: #FFF !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.no {
    background-color: #fff !important;
    color: #666666 !important;
    border: solid 1px #cccccc !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.alertHeader {
    .alert-wrapper {
        .alert-head {
            border-bottom: solid 1px #ccc;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
            margin-bottom: 10px !important;

            h2 {
                color: #eb445a;
                padding-top: 5px !important;
                padding-bottom: 5px !important;
            }
        }
    }
}

//    .alertHeader { 
//     .alert-wrapper {
//       --min-width: 500px;
//     }
//    }

@media screen and (min-width:576px) {
    .select-width {
        .alert-wrapper {
            --min-width: 500px;
        }
    }
}

ion-tab-bar,
ion-tab-button {
    background-color: #fff;
}

ion-accordion ion-item ion-icon {
    margin-top: 3px;
    margin-bottom: 8px;
    font-size: 16px;
}

.datetime-time {
    font-size: 18px !important;
}

ion-split-pane {
    --side-min-width: 218px;
    --side-max-width: 14%;
}

  ion-title { padding-inline: 5px; font-size: 17px; font-weight: 600; line-height: 28px; color: #fff; }

  ion-back-button{
    --icon-font-size: 18px !important;
  }
ion-menu-button {
    //box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    //border: 1px solid #e6ebf1;
    --padding-start: 5px;
    --padding-end: 5px;
    width: 40px;
    height: 40px;
}

@media screen and (max-width:576px){
    .form-header { display: none; }
}

.ios ion-input {
    --padding-top: 10px;
    --padding-end: 0;
    --padding-bottom: 10px;
    --padding-start: 8px;
}

.ios ion-searchbar {
    --background: #fff;
    padding-top: 0px !important;
    height: 46px;
    padding-bottom: 0px;
    padding-inline-start: 4px;
    -webkit-padding-end: 12px;
    padding-inline-end: 4px;
  }

  .ios .radioBtn ion-label {
      font-size: 14px;
  }

  ion-buttons ion-button {
    width: 35px !important;
    height: 35px !important;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    border: 1px solid #e6ebf1;
    margin-right: 5px !important;
    border-radius: 23px;
    --padding-end: 8px !important;
    --padding-start: 8px !important;
  }
 
  table tr:last-child {
    border-bottom: 1px solid #dee2e6 !important;
  }

  ion-loading.custom-loading {
    --background: #e3edff;
    --spinner-color: #5d5b76 ;
    color: #5d5b76;
  }

  .table td, .table th {
    padding: 0.5rem 0.55rem !important;
    //border: 1px solid #dfe2e5;
    border-color: #dfe2e5;
 }

    .ngx-pagination li {
        border-left: solid 1px #dee2e6 !important;
        border-top: solid 1px #dee2e6 !important;
        border-bottom: solid 1px #dee2e6 !important;
        margin-right: 0 !important;
      }

      .ngx-pagination li:last-child {
        border-right: solid 1px #dee2e6 !important; 
    }

    ion-select { color: #4e4e4e !important; width: 100%; --font-weight: 400 !important; --placeholder-color: #a7a7a7; --placeholder-opacity: 1;
       // --padding-top: 5px !important;
        //--padding-end: 0px !important;
       // --padding-bottom: 5px !important; 
        //--padding-start: 8px;
     }

     ion-input {
        --padding-start: 0px !important;
     }

     ion-input.has-focus {
        border: 1px solid #228bd2 !important;
      }

      ion-textarea:focus {
        border: 1px solid #228bd2 !important;
      }

      ion-select:focus {
        border: 1px solid #228bd2 !important;
        box-shadow: none !important;
      }

     .searchBtn {
         margin-top: 18px;
     }

     ion-radio {
        width: 15px;
        height: 15px;
     }

.select_option { 
    color: #4e4e4e;
    z-index: 99;
    font-size: 15px;
}

.select_option:hover { 
    --background: #228bd2 !important;
    --color: #FFF !important;
    z-index: 99;
}

table {
    margin-bottom: 10px !important;
}



//  ion-modal {
//     --width: 290px;
//     --border-radius: 8px;
// }

.approved { background: #2dd36f; font-size: 13px; padding: 2px 13px; width: 50px; color: #FFF !important; text-align: center; font-weight: 600; border-radius: 10px; }
 
.rejected { background: #eb445a; font-size: 13px; padding: 2px 13px; width: 50px; color: #FFF !important; text-align: center; font-weight: 600; border-radius: 10px; }

.inprogress { background: #3880ff; font-size: 13px; padding: 2px 13px; width: 50px; color: #FFF !important; text-align: center; font-weight: 600; border-radius: 10px; }

@media screen and (min-width:1200px){
    .toastAfterHeader{ position: fixed; top:20px; width: 300px; margin-left: 75% !important; } 
}

@media screen and (min-width:1270px){
    .toastAfterHeader{ position: fixed; top:20px; width: 300px; margin-left: 76% !important; } 
}

@media screen and (min-width:1300px){
    .toastAfterHeader{ position: fixed; top:20px; width: 300px; margin-left: 77% !important; } 
}

@media screen and (min-width:992px) and (max-width:1050px){
    .toastAfterHeader{ position: fixed; top:20px; width: 300px; margin-left: 70% !important; } 
}

@media screen and (min-width:1051px) and (max-width:1100px){
    .toastAfterHeader{ position: fixed; top:20px; width: 300px; margin-left: 71% !important; } 
}

@media screen and (min-width:1101px) and (max-width:1150px){
    .toastAfterHeader{ position: fixed; top:20px; width: 300px; margin-left: 72% !important; } 
}

@media screen and (min-width:1151px) and (max-width:1200px){
    .toastAfterHeader{ position: fixed; top:20px; width: 300px; margin-left: 74% !important; } 
}

.toastAfterHeader {
    font-weight: 600 !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield;
}

.ng-select-container {
    height: 32px !important;
    background: #fafafa !important;
    font-size: 13px !important;
    color: #495057 !important;
}

.ng-option-label {
    font-size: 13px !important;
    color: #495057 !important;
}

ion-buttons ion-icon {
    color: #FFF;
}

ion-back-button {
    color: #FFF !important;
}

ion-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }